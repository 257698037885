<div class="media profile-media">
  <div class="media-body">
    <span> {{ "User" | ioxTranslate }}: {{ userName | titlecase }}</span>
    <p class="mb-0 font-roboto">
      {{ "Admin" | ioxTranslate }} <i class="middle fa fa-angle-down"></i>
    </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li (click)="changePassword()">
    <app-feather-icons [icon]="'settings'"></app-feather-icons
    ><span>{{ "Change Password" | ioxTranslate }}</span>
  </li>
  <li (click)="logoutFunc()">
    <app-feather-icons [icon]="'log-in'"></app-feather-icons
    ><span>{{ "Log out" | ioxTranslate }}</span>
  </li>
</ul>
