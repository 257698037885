import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Alarm } from "src/app/models/api/alarms";
import { CustomerApiService } from "src/app/shared/services/api/customer-api.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit, OnDestroy {
  countAlarms: Number;
  public openNotification: boolean = false;
  private subscription: Subscription;
  public alarms: Alarm[] = [];
  constructor(
    private customerApiService: CustomerApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getAlarms();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  getAlarms(): void {
    this.subscription = this.customerApiService
      .getAlarms("1.0")
      .subscribe((data) => {
        this.alarms = data;
        this.countAlarms = this.alarms.length;
      });
  }

  getBadgeClass(lev: number): string {
    switch (lev) {
      case 1:
        return "b-l-critical";
      case 2:
        return "b-l-major";
      case 3:
        return "b-l-minor";
      case 4:
        return "b-l-warning";
      default:
        return "";
    }
  }

  closeAndNavigate() {
    this.router.navigate(["/alarms"]);
  }
}
