import { Pipe, PipeTransform } from "@angular/core";

interface SensorValueEntry {
  propName: string;
  propValue: any;
}
@Pipe({ name: "sensorValueEntries" })
export class SensorValueEntriesPipe implements PipeTransform {
  transform(value: any): string {
    if (Array.isArray(value)) {
      // Handle the case where the value is an array of SensorValueEntry objects
      return value
        .map((entry) => `${entry.propName}=${entry.propValue}`)
        .join("\n");
    } else if (value && typeof value === "object") {
      // Handle normal key-value object
      return Object.entries(value)
        .map(([key, val]) => `${key}: ${val}`)
        .join("\n");
    } else {
      // Handle primitive values (string, number, etc.)
      return String(value);
    }
  }
}
