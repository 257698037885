import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";

hljs.registerLanguage("json", json);
@Component({
  selector: "app-modal-details-viewer",
  templateUrl: "./modal-details-viewer.component.html",
  styleUrls: ["./modal-details-viewer.component.scss"],
})
export class ModalDetailsViewerComponent implements OnChanges {
  objectKeys = Object.keys;
  @Input() sensorDetails: Object;
  @Input() lastSensorValue: Object;
  formattedJson;
  activeTab: Number;

  constructor(public modal: NgbActiveModal) {
    this.activeTab = 2;
  }
  ngOnInit(): void {
    this.updateFormattedJson();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lastSensorValue) {
      this.updateFormattedJson();
    }
  }

  private updateFormattedJson(): void {
    this.formattedJson = hljs.highlight(
      JSON.stringify(this.lastSensorValue, null, 2),
      { language: "json" }
    ).value;
  }
}
