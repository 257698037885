import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { GoogleMap } from "@angular/google-maps";
import { Location } from "src/app/models/api/location";
import { SensorService } from "../../services/api/sensor.service";
import { AlertService } from "../../services/core/alert.service";
import { SensorManagerService } from "../../services/api/sensor-manager.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-google-map",
  templateUrl: "./google-map.component.html",
  styleUrls: ["./google-map.component.scss"],
})
export class GoogleMapComponent implements OnInit {
  public markers: any[];
  public zoom: number;
  public location: Location;
  @Input() isNewEntity: boolean;
  @Input() data: any;
  @ViewChild(GoogleMap) map!: GoogleMap;
  @Output() locationChange = new EventEmitter();
  marker: any; // Holds the current marker
  center: google.maps.LatLngLiteral;
  constructor(
    private dataService: SensorService,
    private alertService: AlertService,
    private sensorMangerService: SensorManagerService,
    private router: Router
  ) {
    this.markers = [];
    this.zoom = 2;
    this.location = this.createLocationEmptyModel();
  }

  ngOnInit() {
    this.zoom = 12;
    if (this.data.location != null && this.data.location.trim() != "") {
      this.location = JSON.parse(this.data.location);
      this.center = {
        lat: this.location.latitude,
        lng: this.location.longitude,
      };
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("getting current");
          this.location.latitude = position.coords.latitude;
          this.location.longitude = position.coords.longitude;
          this.center = {
            lat: this.location.latitude,
            lng: this.location.longitude,
          };
        },
        () => {
          console.error("Geolocation failed or not allowed.");
          this.center = { lat: 0, lng: 0 }; // Fallback center
        }
      );
    }
    this.addMarker(this.location.latitude, this.location.longitude);
  }

  addMarker(lat: number, lng: number) {
    this.markers = [];
    // Add new marker
    this.marker = {
      position: { lat, lng },
      options: {
        draggable: true,
        animation: google.maps.Animation.DROP,
      },
    };
    this.markers.push(this.marker);
  }

  ngAfterViewInit() {
    const input = document.getElementById("pac-input") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(input);
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places.length === 0) {
        return;
      }
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
        // Set the address from the place details
        if (place.formatted_address) {
          this.location.address = place.formatted_address;
        }

        this.addMarker(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          this.zoom = 17;
        }
      });
      this.sendLocationValueBack();
    });
  }

  onMarkerDragEnd(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.location.latitude = event.latLng.lat();
      this.location.longitude = event.latLng.lng();

      // Update the marker's position
      if (this.marker) {
        this.marker.position = {
          lat: this.location.latitude,
          lng: this.location.longitude,
        };
      }
      // Reverse geocode the new position to get the address
      this.reverseGeocode(this.location.latitude, this.location.longitude);
    }
    this.sendLocationValueBack();
  }

  reverseGeocode(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder();
    const latlng = {
      lat: lat,
      lng: lng,
    };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.location.address = results[0].formatted_address;
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  }

  createLocationEmptyModel(): Location {
    const data = new Location();
    data.details = null;
    data.latitude = 0.0;
    data.longitude = 0.0;
    data.address = null;
    return data;
  }

  update() {
    console.log(this.location, "RRRRRRRRRRRRRRRRUPDATE");
    this.data.location = JSON.stringify(this.location);
    console.log(this.data, "dataaaaaaaaaaa");
    this.dataService.update(this.data).subscribe({
      next: (newEntity) => {
        this.sensorMangerService.getSensors(() => {});
        // this.sensorMangerService.getSensors();
        this.alertService.showSuccess("Update Location Successfully");
      },
      error: (error) => {
        console.log("error", error);
        this.alertService.showError("Update Location Failed: " + error);
      },
    });
    window.scrollTo(0, 0);
  }

  sendLocationValueBack() {
    this.data.location = JSON.stringify(this.location);
    this.locationChange.emit(this.data.location);
  }

  cancel() {
    this.router.navigate(["/devices/details", String(this.data.id)]);
  }
}
