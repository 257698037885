<div class="map-container">
  <input
    id="pac-input"
    class="form-control"
    type="text"
    placeholder="Search Address"
  />

  <div class="row m-t-20">
    <div class="col-xl-12">
      <div class="map-js-height">
        <google-map width="100%" height="350px" [center]="center" [zoom]="zoom">
          <map-marker
            *ngFor="let marker of markers"
            [position]="marker.position"
            [options]="marker.options"
            (mapDragend)="onMarkerDragEnd($event)"
          ></map-marker>
        </google-map>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-6 mb-3">
    <label for="details">{{ "Name/Other Details" | ioxTranslate }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="details"
      [(ngModel)]="location.details"
    />
  </div>
  <div class="col-md-3 mb-3">
    <label for="latitude">{{ "Latitude" | ioxTranslate }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="latitude"
      [(ngModel)]="location.latitude"
    />
  </div>
  <div class="col-md-3 mb-3">
    <label for="longitude">{{ "Longitude" | ioxTranslate }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="longitude"
      [(ngModel)]="location.longitude"
    />
  </div>
</div>
<div class="row">
  <div class="col-md-12 mb-3">
    <label for="address">{{ "Address" | ioxTranslate }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="address"
      [(ngModel)]="location.address"
    />
  </div>
</div>

<!-- <button
  *ngIf="!isNewEntity"
  class="btn btn-primary btn-sm col-md-12"
  (click)="update()"
>
  {{ "Update Location" | ioxTranslate }}
</button> -->

<div *ngIf="!isNewEntity" class="card-footer text-end p-0">
  <button type="button" class="btn btn-light btn-sm m-t-20" (click)="cancel()">
    {{ "Cancel" | ioxTranslate }}
  </button>
  <button class="btn btn-primary btn-sm m-t-20 m-l-5" (click)="update()">
    {{ "Update Location" | ioxTranslate }}
  </button>
</div>
<!-- <input
  id="pac-input"
  class="form-control"
  type="text"
  placeholder="Search Address"
/>
<div class="row m-t-20">
  <div class="col-xl-12">
    <div class="map-js-height">
      <google-map width="100%" height="450px" [center]="center" [zoom]="zoom">
        <map-marker
          *ngFor="let marker of markers"
          [position]="marker.position"
          [options]="marker.options"
          (mapDragend)="onMarkerDragEnd($event)"
        ></map-marker>
      </google-map>
    </div>
  </div>
</div> -->
