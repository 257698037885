import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenService } from "../services/core/authen.service";

const LOGIN_URL = "/auth/login";
@Injectable({
  providedIn: "root",
})
export class AdminGuard {
  constructor(public router: Router, private authenService: AuthenService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    if (this.authenService.isLoggedIn()) {
      return true;
    } else {
      console.log("not loggggggggggggggggggggggggged in");
      this.router.navigate([LOGIN_URL]);
      // return true;
    }
  }
}
