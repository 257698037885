import { Component, Input, Output, EventEmitter } from "@angular/core";

export interface Pagination {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent {
  @Input() maxSize: number = 7;
  @Input() pagination: Pagination;
  @Output() pageChanged = new EventEmitter<number>();
  endPage: number = 1;

  get pages(): number[] {
    const totalPages = this.pagination.pageCount ?? Math.ceil(this.pagination.total / this.pagination.pageSize);
    const startPage = Math.max(
      this.pagination.page - Math.floor(this.maxSize / 2),
      1
    );
    this.endPage = Math.min(startPage + this.maxSize, totalPages);

    const pages = [];
    for (let i = startPage; i <= this.endPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  changePage(page: number): void {
    if (page > 0 && page <= this.endPage) {
      this.pagination.page = page;
      this.pageChanged.emit(page);
    }
  }
}
