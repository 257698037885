<div class="card-body d-flex justify-content-end p-0">
  <div class="m-r-10 m-t-10">Total Rows: {{ pagination.total }}</div>
</div>
<ul class="pagination">
  <li class="page-item" [class.disabled]="pagination.page === 1">
    <button
      [disabled]="pagination.page == 1"
      class="page-link"
      aria-label="Previous"
      (click)="changePage(pagination.page - 1)"
    >
      <i class="fa fa-angle-left"></i>
    </button>
  </li>
  <li
    *ngFor="let page of pages"
    class="page-item"
    [class.active]="page === pagination.page"
  >
    <button
      class="page-link"
      (click)="this.pagination.page != page && changePage(page)"
    >
      {{ page }}
    </button>
  </li>
  <li class="page-item" [class.disabled]="pagination.page === endPage">
    <button
      class="page-link"
      aria-label="Next"
      (click)="changePage(pagination.page + 1)"
    >
      <i class="fa fa-angle-right"></i>
    </button>
  </li>
</ul>
