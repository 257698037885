import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("../../components/pages/pages.module").then((m) => m.PagesModule),
  },
  // {
  //   path: "sample-page",
  //   component: SamplePageComponent,
  //   data: {
  //     title: "Sample Page",
  //     breadcrumb: "Sample Page",
  //   },
  // },
];
