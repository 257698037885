<div class="modal-header">
    <h4 class="modal-title">Confirm Delete</h4>
    <button type="button" class="close btn py-1 px-2 btn-secondary" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    Aer you sure you want to delete event, <b>{{title}}</b> ?
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="closeModal(true)">Delete</button>
</div>