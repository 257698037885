import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { InfluxService } from "../../services/api/influx.service";
import { AlertService } from "../../services/core/alert.service";
import { LocalStorageService } from "../../services/core/storage.service";

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public elem: any;
  isDark: boolean;
  public lightLogoUrl: string;
  public darkLogoUrl: string;
  constructor(
    public layout: LayoutService,
    public navServices: NavService,
    public service: InfluxService,
    public store: LocalStorageService,
    private alertService: AlertService,
    @Inject(DOCUMENT) private document: any
  ) {
    // this.isDark = false;
    if (this.store.get("theme_app_tone")) {
      if (this.store.get("theme_app_tone") === "light-only") {
        this.isDark = true;
      } else {
        this.isDark = false;
      }
    } else {
      this.isDark = false;
    }
  }

  ngOnInit() {
    this.lightLogoUrl = localStorage.getItem("logo_url");
    this.darkLogoUrl = localStorage.getItem("dark_logo_url");
    this.elem = document.documentElement;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    this.isDark = !this.isDark;
    if ((this.layout.config.settings.layout_version = "dark-only")) {
      document.body.classList.toggle("dark-only");
    }
    if (this.isDark) {
      this.layout.changeTheme("dark");
      this.store.set("theme_app_tone", "light-only");
    } else {
      this.layout.changeTheme("light");
      this.store.set("theme_app_tone", "dark-only");
    }
    document.body.remove;
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  customizeSidebarType() {
    if (this.layout.config.settings.layout === "horizontal-wrapper") {
      this.layout.config.settings.layout = "Dubai";
      this.store.set("layout", "Dubai");
    } else {
      this.layout.config.settings.layout = "horizontal-wrapper";
      this.store.set("layout", "horizontal-wrapper");
    }
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  downloadManual() {
    this.service.getUserManual().subscribe({
      next: (blob) => {
        // Create a blob link to download
        const a = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = "User Manual.pdf"; // Name of the file
        a.click();
        // URL.revokeObjectURL(objectUrl);
        window.open(objectUrl);
      },
      error: (error) => {
        console.error("Error downloading the file:", error);
        // this.alertService.showError("Error downloading the file:", error);
      },
    });
  }
}
