import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { AlertService } from "../../services/core/alert.service"; // Import the AlertService

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent {
  staticAlertClosed = false;
  private subscription: Subscription;
  message: string;
  messageType: "success" | "danger";

  constructor(private alertService: AlertService) {
    this.subscription = this.alertService.getAlerts().subscribe((alert) => {
      this.staticAlertClosed = false;
      this.message = alert.message;
      this.messageType = alert.type;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close() {
    this.message = null;
  }
}
