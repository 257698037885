import { Injectable } from "@angular/core";
import { LocalStorageService } from "./core/storage.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { EnvService } from "./core/env.service";
import { Menu } from "./nav.service";
import { NavService } from "./nav.service";
import { GrafanaService } from "./api/grafana.service";
@Injectable({
  providedIn: "root",
})
export class LayoutService {
  public menuItems: Menu[];
  themeKey = "unset";
  theme: string;
  public config = {
    settings: {
      layout: "horizontal-wrapper",
      layout_type: "ltr",
      sidebar_type: "compact-wrapper",
      layout_version: "light-only",
      icon: "stroke-svg",
    },
    color: {
      primary_color: "#ff5000",
      secondary_color: "#d1cccc",
    },
  };

  constructor(
    private store: LocalStorageService,
    private env: EnvService,
    private grafana: GrafanaService,
    private navService: NavService
  ) {
    this.subscribeToItems();
    this.themeKey = env.tenant + "_theme";
    this.config.settings.layout_version = this.store.get("theme_app_tone");
    this.config.settings.layout = this.store.get("layout");
    if (this.config.settings.layout_version === "dark-only") {
      document.body?.classList.add("dark-only");
    } else {
      document.body?.classList.add("light-only");
    }
    if (this.config.settings.layout_type == "rtl")
      document
        .getElementsByTagName("html")[0]
        .setAttribute("dir", this.config.settings.layout_type);

    document.documentElement.style.setProperty(
      "--theme-deafult",
      localStorage.getItem("primary_color")
    );
    document.documentElement.style.setProperty(
      "--theme-secondary",
      localStorage.getItem("secondary_color")
    );
    var primary =
      localStorage.getItem("primary_color") ||
      this.config.color.secondary_color;
    var secondary =
      localStorage.getItem("secondary_color") ||
      this.config.color.secondary_color;
    this.config.color.primary_color = primary;
    this.config.color.secondary_color = secondary;
    localStorage.getItem("primary_color") || this.config.color.primary_color;
    localStorage.getItem("secondary_color") ||
      this.config.color.secondary_color;
  }

  private subscribeToItems() {
    this.navService.items.subscribe((items) => {
      this.menuItems = items;
      const mPos = this.menuItems.find((item) => item.path === "/grafana");
      if (mPos.children) {
        this.grafana.getDashboard().subscribe((res) => {
          res.forEach((dashboard: any) => {
            if (dashboard.tags.includes(this.env.app)) {
              const gfMenuItem = {
                path: "/grafana/" + dashboard.title,
                title: dashboard.title,
                type: "link",
              };
              mPos.children.push(gfMenuItem);
            }
          });
        });
      }
    });
    console.log(this, this.menuItems, "____________");
  }

  get notify(): Observable<any> {
    return this.notify$.asObservable();
  }
  private notify$ = new BehaviorSubject<any>({});

  getOptions() {
    const theme = this.store.get(this.themeKey);
    this.config.settings.layout_version =
      theme == "light-only" || theme === "dark-only"
        ? theme
        : this.config.settings.layout_version;
    return this.config.settings.layout_version;
  }

  setColor(primary_color, secondary_color) {
    this.config.color.primary_color = primary_color;
    this.config.color.secondary_color = secondary_color;
    localStorage.setItem("primary_color", this.config.color.primary_color);
    localStorage.setItem("secondary_color", this.config.color.secondary_color);
    if (!this.store.get("theme_app_tone")) {
      localStorage.setItem("theme_app_tone", "light-only");
    }
    // window.location.reload();
  }

  resetColor() {
    document.documentElement.style.setProperty("--theme-deafult", "#ff5000");
    document.documentElement.style.setProperty("--theme-secondary", "#d1cccc");
    (<HTMLInputElement>document.getElementById("ColorPicker1")).value =
      "#ff5000";
    (<HTMLInputElement>document.getElementById("ColorPicker2")).value =
      "#d1cccc";
    localStorage.setItem("primary_color", "#ff5000");
    localStorage.setItem("secondary_color", "#d1cccc");
    window.location.reload();
  }

  load() {
    // this.subscribeToItems();
    // Load Colors of Tenant
    this.setColor(
      this.store.get("primary_color"),
      this.store.get("accent_color")
    );
  }

  changeTheme(theme: "light" | "dark") {
    this.store.set(this.themeKey, theme);
    this.theme = theme;
    this.notify$.next({ theme });
    console.log("fin");
  }
}
