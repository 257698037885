<div class="container-fluid">
  <div class="row">
    <div
      *ngIf="tenantStatus"
      class="col-xl-7"
      [ngStyle]="{
        'background-image': 'url(' + imageBg + ')',
        'background-size': 'cover',
        'background-position': 'center center',
        display: 'flex'
      }"
    ></div>
    <div *ngIf="!tenantStatus" class="col-xl-7 dt-mt-auth-container"></div>
    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>
          <div class="center-content">
            <img
              (click)="changeTenant($event)"
              class="img-fluid for-light m-b-10"
              src="{{ imageLogo }}"
              width="{{ width }}"
              height="{{ height }}"
            />
            <p *ngIf="showClicks" class="text-right">
              <strong>{{ clicksCount }}</strong>
            </p>
          </div>
          <!-- select app -->
          <div class="login-main" *ngIf="!tenantStatus">
            <form class="theme-form" [formGroup]="tenantForm">
              <h4>Welcome to IOTONIX!</h4>
              <div class="form-group">
                <label class="col-form-label">App Name</label>
                <input
                  class="form-control"
                  type="text"
                  required="'true'"
                  placeholder=""
                  formControlName="tenant"
                />
                <div *ngIf="errorMsgTenant" class="text text-danger mt-1">
                  {{ errorMsgTenant }}
                </div>
              </div>
              <div class="form-group mb-0">
                <button
                  class="btn btn-primary d-block w-100"
                  type="submit"
                  (click)="handleTenant()"
                >
                  OK
                </button>
              </div>
            </form>
          </div>

          <!-- login -->
          <div class="login-main" *ngIf="tenantStatus">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Sign in to account</h4>
              <p>Enter your username & password to login</p>
              <div class="form-group">
                <label class="col-form-label">Username</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="username"
                />
              </div>
              <div class="form-group m-b-20">
                <label class="col-form-label">Password</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    formControlName="password"
                    [type]="show ? 'text' : 'password'"
                    name="login[password]"
                    required=""
                    placeholder="*********"
                  />
                  <span class="input-group-text">
                    <i
                      *ngIf="!show"
                      class="fa fa-eye-slash"
                      (click)="showPassword()"
                    ></i>
                    <i
                      *ngIf="show"
                      class="fa fa-eye"
                      (click)="showPassword()"
                    ></i>
                  </span>
                </div>
              </div>
              <div *ngIf="errorMsg" class="text text-danger mt-1">
                {{ errorMsg }}
              </div>
              <div class="form-group mb-0">
                <button
                  class="btn btn-primary d-block w-100"
                  type="submit"
                  (click)="handleLogin()"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
