<div class="container-fluid">
  <div class="row">
    <div class="modal-header">
      <h6 class="modal-title" id="modal-basic-title">
        {{ "Details" | ioxTranslate }}
      </h6>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-content">
      <div class="col-sm-12 col-xl-12 m-t-5">
        <!-- <div class="card">
          <div class="card-body"> -->
        <ul
          ngbNav
          #nav="ngbNav"
          class="nav-tabs nav-fill"
          [(activeId)]="activeTab"
        >
          <li [ngbNavItem]="1" *ngIf="sensorDetails">
            <a ngbNavLink> {{ "Sensor Details" | ioxTranslate }}</a>
            <ng-template ngbNavContent>
              <div class="table-responsive">
                <table class="table table-hover table-striped table-sm">
                  <tbody>
                    <tr *ngFor="let key of objectKeys(sensorDetails)">
                      <th scope="row">{{ key | capitalize }}</th>
                      <td>
                        <div class="pre-style">
                          {{ sensorDetails[key] | objectToString }}
                        </div>
                      </td>
                      <!-- <td>{{ sensorDetails[key] | objectToString }}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>{{ "Last Sensor Value " | ioxTranslate }}</a>
            <ng-template ngbNavContent>
              <div class="table-responsive">
                <table class="table table-hover table-striped table-sm">
                  <tbody>
                    <tr *ngFor="let key of objectKeys(lastSensorValue)">
                      <th scope="row">{{ key | capitalize }}</th>
                      <td>
                        <div class="pre-style">
                          {{ lastSensorValue[key] | sensorValueEntries }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink> {{ "Last Sensor Value (Json) " | ioxTranslate }}</a>
            <ng-template ngbNavContent>
              <!-- <pre>{{ lastSensorValue | json }}</pre> -->
              <pre><code [innerHTML]="formattedJson"></code></pre>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
        <div class="mt-3"></div>
      </div>
    </div>
  </div>
</div>
<!-- </div>
</div> -->
