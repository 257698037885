import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class CalendarEventsService {
  url = "https://ioxpartner.ait.co.th/strapi/api/calendar-events/";
  token =
    "bf1f05d97ff27d471a91215990f625ff73e1670688df4b7110e97b072d83f892de5e0bdb2f7b38070aaab1aced161c4509153f486fcb08dfa4dbd223e8acd5d285356c673c8e39339f2e17fb64c50013973ed7d68bbee492e15c6ad6b81537a161403b5f633206e20f37e106c9b5886fa21c0d07510a7496db229196d20d84d9";
  constructor(private http: HttpClient) {}

  getEvents() {
    return this.http.get<any>(this.url, this.getHeader());
  }

  getEventById(id: number) {
    return this.http.get<any>(this.url + id, this.getHeader());
  }

  addEvent(event: any) {
    return this.http.post<any>(this.url, { data: event }, this.getHeader());
  }

  updateEvent(event: any) {
    return this.http.put<any>(
      this.url + event.id,
      { data: event },
      this.getHeader()
    );
  }

  deleteEvent(id: number) {
    return this.http.delete<any>(this.url + id, this.getHeader());
  }

  getHeader() {
    return {
      headers: new HttpHeaders({
        Authorization: `bearer ${this.token}`,
        "Content-Type": "application/json",
      }),
    };
  }
}
