import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms"; // Import NgForm

@Component({
  selector: "app-modal-confirm-input",
  templateUrl: "./modal-confirm-input.component.html",
  styleUrls: ["./modal-confirm-input.component.scss"],
})
export class ModalConfirmInputComponent {
  @Input() title: string;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  modalData: any;

  @ViewChild("myForm", { static: true }) myForm: NgForm; // Create a reference to the form

  constructor(public modal: NgbActiveModal) {}

  save() {
    if (this.myForm.valid) {
      // Check if the form is valid before emitting the onSave event
      this.onSave.emit(this.modalData);
      this.modal.close("Save click");
    }
  }
}
