import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";
import { Subject, Observable } from "rxjs";
import { LocalStorageService } from "./storage.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class JwtService {
  private authUrl: string;
  jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(
    private http: HttpClient,
    private env: EnvService,
    private store: LocalStorageService
  ) {
    this.authUrl = this.env.coreApi + "/User/Login";
  }

  login(username: string, password: string): Observable<any> {
    const body = { username, password };
    return this.http.post<any>(this.authUrl, body);
  }

  logout() {
    this.store.remove("access_token");
  }

  public isTokenExpired() {
    return this.jwtHelper.isTokenExpired(localStorage.getItem("access_token"));
  }
}
