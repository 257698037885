import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { JwtService } from "./jwt.service";
import { LocalStorageService } from "./storage.service";
import { EnvService } from "./env.service";
@Injectable({
  providedIn: "root",
})
export class AuthenService {
  private authUrl: string;
  jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(
    private http: HttpClient,
    private env: EnvService,
    private store: LocalStorageService,
    private token: JwtService
  ) {
    this.authUrl = this.env.coreApi + "/User/Login";
  }

  login(username: string, password: string): Observable<any> {
    const body = { username, password };
    return this.http.post<any>(this.authUrl, body);
  }

  logout() {
    this.store.remove("access_token");
  }

  isTokenExpired() {
    return this.jwtHelper.isTokenExpired(localStorage.getItem("access_token"));
  }

  isLoggedIn() {
    return (
      this.store.get("access_token") !== null && !this.token.isTokenExpired()
    );
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }
}
