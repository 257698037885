<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{ 'Edit Svg' | ioxTranslate }}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <angular-editor #editor [config]="editorConfig" [(ngModel)]="text"></angular-editor>
</div>
<div class="modal-footer">
    <button (click)="save()" class="btn btn-outline-success">
        {{ "Save Changes" | ioxTranslate }}
    </button>
</div>