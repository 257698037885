import { Pipe, PipeTransform } from "@angular/core";
import { IoxLanguageService } from "../services/core/iox-language.service";

@Pipe({
  name: "ioxTranslate",
})
export class IoxTranslatePipe implements PipeTransform {
  constructor(private translator: IoxLanguageService) {}

  transform(value: string): string {
    // return value;
    return this.translator.getTranslation(value);
  }
}
