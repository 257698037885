import {
  Directive,
  ElementRef,
  AfterViewInit,
  ComponentFactoryResolver,
} from "@angular/core";
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";

@Directive({
  selector: "[appHighlightJson]", // Use a unique selector for your directive
})
export class HighlightJsonDirective implements AfterViewInit {
  constructor(private el: ElementRef) {
    hljs.registerLanguage("json", json); // Register the JSON language for highlighting
  }

  ngAfterViewInit() {
    hljs.highlightElement(this.el.nativeElement); // Apply highlighting to the element
  }
}
