import { Injectable } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SensorList } from "src/app/models/api/sensor-list";
import { map } from "rxjs/operators";
import { EnvService } from "../core/env.service";
import { Sensor } from "src/app/models/api/sensor";

@Injectable({
  providedIn: "root",
})
export class SensorService {
  private sensorSource = new BehaviorSubject<Sensor | null>(null);
  currentSensor = this.sensorSource.asObservable();
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }),
  };
  private sensorsUrl: string;
  constructor(private http: HttpClient, private env: EnvService) {
    this.sensorsUrl = this.env.coreApi + "/Sensor";
  }

  changeSensor(sensor: Sensor) {
    this.sensorSource.next(sensor);
  }

  getAllSensors(): Observable<SensorList> {
    return this.http.get<SensorList>(this.sensorsUrl).pipe(
      map((res: SensorList) => {
        if (res.sensors !== null) {
          const filteredSensors = res.sensors.filter(
            (x) => x.inCompoundSensor !== true
          );
          res.sensors = [...filteredSensors];
        }
        return res;
      })
    );
  }

  getSensors(): Observable<SensorList> {
    return this.http.get<SensorList>(this.sensorsUrl).pipe(
      map((res: SensorList) => {
        if (res.sensors !== null) {
          const filteredSensors = res.sensors.filter(
            (x) => x.inCompoundSensor !== true
          );
          res.sensors = [...filteredSensors];
        }
        return res;
      })
    );
  }

  persist(sensors: Sensor[]) {
    localStorage.setItem("sensors", JSON.stringify(sensors));
  }

  getFinalSensors(): Sensor[] {
    return JSON.parse(localStorage.getItem("sensors"));
  }

  public getSensor(name: string): Observable<Sensor> {
    return this.http.get(this.sensorsUrl).pipe(
      map((res: SensorList) => {
        if (res.sensors !== null) {
          const filteredSensors = res.sensors.filter(
            (x) => x.inCompoundSensor !== true
          );
          res.sensors = [...filteredSensors];
        }
        const foundSensor = res.sensors.filter(
          (str) => str.name.toLowerCase() === name.toLowerCase()
        )[0];
        return foundSensor;
      })
    );
  }

  //
  // NEW Cruditor  required service functions below
  //
  public get(): Observable<Sensor[]> {
    return this.http.get(this.sensorsUrl).pipe(
      map((res: SensorList) => {
        if (res.sensors !== null) {
          const filteredSensors = res.sensors.filter(
            (x) => x.inCompoundSensor !== true
          );
          res.sensors = [...filteredSensors];
        }
        return res.sensors;
      })
    );
  }

  public insert(sensor: Sensor): Observable<Sensor> {
    return this.unsert(sensor);
  } //end of insertSensors

  public update(sensor: Sensor): Observable<Sensor> {
    return this.unsert(sensor);
  } //end of insertSensor

  private unsert(sensor: Sensor): Observable<Sensor> {
    const body = JSON.stringify(sensor);
    if (sensor.clazz !== "Sensor") {
      return this.http.patch<Sensor>(
        this.sensorsUrl + "/" + sensor.clazz,
        body,
        this.httpOptions
      );
    } else {
      return this.http.patch<Sensor>(this.sensorsUrl, body, this.httpOptions);
    }
  } //end of unsert

  public delete(sensor: Sensor): Observable<boolean> {
    return this.http.delete<boolean>(this.sensorsUrl + "/" + sensor.id);
  } //end of delete
}
