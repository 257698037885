<div class="notification-box" (click)="toggleNotificationMobile()">
  <svg>
    <use href="assets/svg/icon-sprite.svg#notification"></use>
  </svg>
  <span class="badge rounded-pill badge-primary">{{ countAlarms }}</span>
</div>
<div class="onhover-show-div notification-dropdown">
  <h6 class="f-18 mb-0 dropdown-title">Alarms</h6>
  <ul>
    <li
      *ngFor="let alarm of alarms | slice : 0 : 5"
      [ngClass]="getBadgeClass(alarm.lev)"
      class="border-4"
      (click)="closeAndNavigate()"
    >
      <p>
        {{ alarm.alarmName }}
        <span class="font-secondary"> {{ alarm.devName }}</span>
      </p>
    </li>
    <li><a class="f-w-700" (click)="closeAndNavigate()">Check all</a></li>
  </ul>
</div>
