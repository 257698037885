import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "objectToString" })
export class ObjectToStringPipe implements PipeTransform {
  transform(value: any): any {
    if (Array.isArray(value)) {
      // Handle an array of objects
      return value.map((item) => this.handleItem(item)).join("\n");
    } else if (typeof value === "string") {
      // Handle a JSON string
      try {
        const object = JSON.parse(value);
        return this.parseObject(object);
      } catch (e) {
        return value; // Return the original string if parsing fails
      }
    } else if (value && typeof value === "object") {
      // Handle a regular object
      return this.parseObject(value);
    } else {
      // Handle primitive types (number, boolean, etc.)
      return value;
    }
  }

  private handleItem(item: any): string {
    if (
      item &&
      typeof item === "object" &&
      "propName" in item &&
      "propValue" in item
    ) {
      // If the object has propName and propValue properties, format them
      return `${item.propName}: ${item.propValue}`;
    } else {
      // Otherwise, fall back to parsing the object
      return this.parseObject(item);
    }
  }

  private parseObject(obj: any): string {
    return Object.entries(obj)
      .map(([key, val]) => {
        if (val && typeof val === "object") {
          return `${key}: {\n${this.parseObject(val)}\n}`;
        } else {
          return `${key}: ${val}`;
        }
      })
      .join("\n"); // Use '\n' for new lines
  }
}
