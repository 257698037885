import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NavService } from "../../../../services/nav.service";
import { LocalStorageService } from "src/app/shared/services/core/storage.service";
import { IoxLanguageService } from "src/app/shared/services/core/iox-language.service";

@Component({
  selector: "app-languages",
  templateUrl: "./languages.component.html",
  styleUrls: ["./languages.component.scss"],
})
export class LanguagesComponent implements OnInit {
  public language: boolean = false;

  public languages: any[] = [
    {
      language: "English",
      code: "en",
      type: "US",
      icon: "us",
      locale: "en-EN",
    },
    {
      language: "ไทย",
      code: "th",
      icon: "th",
      locale: "th-TH",
    },
  ];

  public selectedLanguage: any;

  constructor(
    public navServices: NavService,
    private translate: TranslateService,
    private store: LocalStorageService,
    private languageService: IoxLanguageService
  ) {
    if (!this.store.get("language")) {
      this.selectedLanguage = {
        language: "English",
        code: "en",
        type: "US",
        icon: "us",
        locale: "en-EN",
      };
    } else {
      const localeToFind = this.store.get("language");
      this.selectedLanguage = this.languages.find(
        (language) => language.locale === localeToFind
      );

      // this.selectedLanguage.locale = this.store.get("language");
    }
  }
  //
  ngOnInit() {}

  changeLanguage(lang) {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
    this.store.set("language", this.selectedLanguage.locale);
    this.languageService
      .fetchTranslations(
        this.store.get("tenantStatus"),
        this.selectedLanguage.locale
      )
      .subscribe((data) => {
        let translations = this.languageService.set(data);
        window.location.reload();
      });
  }
}
