import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-event-delete-confirm",
  templateUrl: "./event-delete-confirm.component.html",
  styleUrls: ["./event-delete-confirm.component.scss"],
})
export class EventDeleteConfirmComponent {
  title: any;
  modalRef: any;
  constructor(public activeModal: NgbActiveModal) {}

  closeModal(isDeleted = false): void {
    this.activeModal.dismiss(isDeleted);
  }
}
