import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
import { FullComponent } from "./components/layout/full/full.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { MegaMenuComponent } from "./components/header/elements/mega-menu/mega-menu.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";
// Directives
// Services
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { ModalConfirmInputComponent } from "./components/modal-confirm-input/modal-confirm-input.component";
import { ModalConfirmComponent } from "./components/modal-confirm/modal-confirm.component";
import { AlertComponent } from "./components/alert/alert.component";
import { SafePipe } from "./pipes/safe.pipe";
import { ObjectToStringPipe } from "./pipes/object-to-string.pipe";
import { CapitalizePipe } from "./pipes/capitalize.pipe";
import { SensorValueEntriesPipe } from "./pipes/sensor-value-pipe";
import { ModalDetailsViewerComponent } from "./components/modal-details-viewer/modal-details-viewer.component";
import { GoogleMapComponent } from "./components/google-map/google-map.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { IoxTranslatePipe } from "./pipes/iox-translate.pipe";
import { NgxDropzoneModule } from "ngx-dropzone";
import { HighlightJsonDirective } from "./directives/highlight-json.directive";
import { DateTimePickerComponent } from "./components/date-time-picker/date-time-picker.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import * as moment from "moment";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { EventPopupComponent } from "./components/event-popup/event-popup.component";
import { AddEventPopupComponent } from "./components/add-event-popup/add-event-popup.component";
import { EventDeleteConfirmComponent } from "./components/event-delete-confirm/event-delete-confirm.component";
import { MediaEditPopupComponent } from './components/media-edit-popup/media-edit-popup.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    SvgIconComponent,
    SwiperComponent,
    FullComponent,
    ModalConfirmInputComponent,
    ModalConfirmComponent,
    AlertComponent,
    ObjectToStringPipe,
    SafePipe,
    CapitalizePipe,
    SensorValueEntriesPipe,
    ModalDetailsViewerComponent,
    GoogleMapComponent,
    IoxTranslatePipe,
    HighlightJsonDirective,
    DateTimePickerComponent,
    PaginationComponent,
    EventPopupComponent,
    AddEventPopupComponent,
    EventDeleteConfirmComponent,
    MediaEditPopupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule.forRoot(),
    CarouselModule,
    SwiperModule,
    GoogleMapsModule,
    NgxDropzoneModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    AngularEditorModule,
  ],

  providers: [NavService, LayoutService, DecimalPipe],

  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    SvgIconComponent,
    SwiperModule,
    AlertComponent,
    SafePipe,
    ObjectToStringPipe,
    CapitalizePipe,
    SensorValueEntriesPipe,
    GoogleMapComponent,
    IoxTranslatePipe,
    NgxDropzoneModule,
    GoogleMapsModule,
    HighlightJsonDirective,
    DateTimePickerComponent,
    CalendarModule,
    PaginationComponent,
    EventPopupComponent,
    AngularEditorModule
  ],
})
export class SharedModule { }
