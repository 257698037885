import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SensorValue } from "src/app/models/api/sensor-value";
import { EnvService } from "../core/env.service";
@Injectable({
  providedIn: "root",
})
export class LastUpdateService {
  private lastUpdateUrl: string;
  constructor(private env: EnvService, private http: HttpClient) {
    this.lastUpdateUrl = this.env.jsApi + "/sensorValues/lastUpdate";
    // this.env.jsApi + "/SensorValue/LastUpdate";
  }

  getLastUpdate() {
    return this.http.get<SensorValue[]>(this.lastUpdateUrl);
  }
}
