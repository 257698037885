<div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="close btn py-1 px-2 btn-secondary" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group">
            <label>Title</label>
            <input type="text" formControlName="title" class="form-control" />
        </div>
        <div class="form-group">
            <label>Description</label>
            <textarea formControlName="description" class="form-control"></textarea>
        </div>
        <div class="form-group">
            <label>Event Date</label>
            <input type="datetime-local" formControlName="eventDate" class="form-control" />
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!form.valid" (click)="onSubmit()">Save</button>
</div>