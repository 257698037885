import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { EnvService } from "../core/env.service";
import { Alarm } from "src/app/models/api/alarms";

@Injectable({
  providedIn: "root",
})
export class CustomerApiService {
  private urlIox: string;

  constructor(private http: HttpClient, private env: EnvService) {
    this.urlIox = `${this.env.server}/ioxcapi/api/v1`;
    // this.urlIox = 'http://localhost:5000/api/v1/logs/GetAlarmListFlattened' // moved to new customer-api
    this.urlIox = `${this.env.server}/ioxcapi/api/v1/logs/GetAlarmListFlattened`;
    console.log("CustomerApiService", this.urlIox);
  } // constructor

  getAlarms(sensorId: string): Observable<Alarm[]> {
    return this.http.get<Alarm[]>(`${this.urlIox}/${sensorId}`);
  } // getDocuments
}
