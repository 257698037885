import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Company } from "../../../models/api/company";
import { Sensor } from "../../../models/api/sensor";
import { TenantConfig } from "../../../models/api/tenant-config";
import { TenantList } from "../../../models/api/tenant-list";
import { TenantWebDetails } from "../../../models/api/tenant-web-details";
import { Constants } from "../../../shared/utils/constants";
import { EnvService } from "../core/env.service";

@Injectable({
  providedIn: "root",
})
export class TenantService {
  private tenantUrl: string;
  private tenantConfigUrl: string;
  constructor(private http: HttpClient, private env: EnvService) {
    this.tenantUrl = this.env.coreApi + "/Tenant";
    this.tenantConfigUrl = this.tenantUrl + "/TenantConfig/ByTenantId";
  }

  get() {
    return this.http.get<TenantList>(this.tenantUrl);
  }

  getTenantConfig(tenantId: string) {
    return this.http.get<TenantConfig>(this.tenantConfigUrl + "/" + tenantId);
  }

  getIp() {
    return this.http.get<any>("https://ipapi.co/json");
  }

  getTenant(id: number) {
    return this.http.get<Company>(this.tenantUrl + "/" + id.toString());
  }

  delete(id: number) {
    return this.http.delete<boolean>(this.tenantUrl + "/" + id.toString());
  }

  unsert(tenant: Company): Observable<Company> {
    const headers = Constants.httpOptions;
    return this.http.patch<Company>(this.tenantUrl, tenant, headers);
  }

  addDevice2Tenant(tenant: Company, sensor: Sensor) {
    // FIXME:
    // check if sensor is not yet in devices of compnay
    // push to device array
    // put to server
    return this.http.put<Company>(this.tenantUrl, tenant);
  }

  getTenantDetails(tenantName: string) {
    return this.http.get<TenantWebDetails[]>(
      this.env.tenantWebDetailsUrl + tenantName
    );
  }
}
