<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">{{ title | ioxTranslate }}</h6>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <form #myForm="ngForm" (ngSubmit)="save()">
    <!-- Add the ngForm directive to your form element -->
    <div class="form-group">
      <label for="remarks"> {{ "Remarks" | ioxTranslate }}</label>
      <input
        class="form-control"
        type="text"
        id="remarks"
        name="remarks"
        [(ngModel)]="modalData"
        required=""
        #remarks="ngModel"
      />
      <p *ngIf="myForm.submitted && !myForm.form.valid" class="text-danger">
        {{ "Remarks are required." | ioxTranslate }}
      </p>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-outline-success">
        {{ "Confirm" | ioxTranslate }}
      </button>
    </div>
  </form>
</div>
