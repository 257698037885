<div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="close btn py-1 px-2 btn-secondary" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <b>Date:</b>
    <span> {{eventData.attributes.eventDate | date: 'dd MM yyyy HH:mm a'}} </span>
    <br>
    <p>
        <b>Description: </b>
        {{eventData.attributes.description}}
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="edit()">Edit</button>
    <button type="button" class="btn btn-danger" (click)="delete()">delete</button>
</div>