import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-add-event-popup",
  templateUrl: "./add-event-popup.component.html",
  styleUrls: ["./add-event-popup.component.scss"],
})
export class AddEventPopupComponent implements OnInit {
  form: FormGroup;
  modalTitle: string;
  isAddMode: boolean;
  eventData: any;
  eventId: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.eventId = this.eventData?.id;
    this.modalTitle = this.eventId ? "Edit Event" : "Add Event";
    this.form = new FormGroup({
      title: new FormControl(
        this.eventData?.attributes?.title ?? "",
        Validators.required
      ),
      description: new FormControl(
        this.eventData?.attributes?.description ?? ""
      ),
      eventDate: new FormControl(
        this.eventData?.attributes?.eventDate.replace(/\.\d+Z$/, "") ??
          new Date(),
        Validators.required
      ),
    });
  }

  onSubmit(): void {
    console.log(this.form);
    if (this.form.valid) {
      this.closeModal(true);
    }
  }

  closeModal(update: boolean = false): void {
    if (!update) {
      this.activeModal.dismiss(false);
      return;
    }
    this.activeModal.dismiss({
      ...(this.eventId
        ? { id: this.eventId, ...this.form.value }
        : this.form.value),
      eventDate: new Date(this.form.value.eventDate).toISOString(),
    });
  }
}
