import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { EnvService } from "src/app/shared/services/core/env.service";
import { JwtService } from "src/app/shared/services/core/jwt.service";
import { LocalStorageService } from "src/app/shared/services/core/storage.service";
import { TenantService } from "../../../shared/services/api/tenant.service";
import { LayoutService } from "src/app/shared/services/layout.service";
import { IoxLanguageService } from "src/app/shared/services/core/iox-language.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  tenantForm: FormGroup;
  tenantStatus = false;
  imageBg = "../../images/login/login_bg.jpg";
  imageLogo = "./assets/brand/iox_for_partners.svg";
  width = "250";
  height = "130";
  public errorMsgTenant = "";
  public show: boolean = false;
  public wrongPassword = false;
  public server = "";
  public errorMsg = "";
  private clickTimeout = null;
  clicksCount = 1;
  showClicks = false;
  constructor(
    private renderer: Renderer2,
    private token: JwtService,
    private fb: FormBuilder,
    private store: LocalStorageService,
    private router: Router,
    private tenantService: TenantService,
    public env: EnvService,
    public service: LayoutService,
    private languageService: IoxLanguageService,
    public layout: LayoutService
  ) {
    this.loginForm = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });

    this.tenantForm = this.fb.group({
      tenant: ["", [Validators.required]],
    });
  }

  ngOnInit() {
    document.body?.classList.remove("dark-only");
    document.body?.classList.add("light-only");
    this.server = this.env.coreApi;
    if (this.store.get("tenantStatus")) {
      this.tenantStatus = true;
      this.getTenantTheme();
    }
  }
  public setClickTimeout(callback) {
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      this.showClicks = false;
      callback();
    }, 2000);
  }
  public changeTenant(itemId: string): void {
    if (this.clickTimeout) {
      this.setClickTimeout(() => {});
      this.clicksCount = this.clicksCount + 1;
      if (this.clicksCount > 3) {
        this.showClicks = true;
      }
      if (this.clicksCount == 10) {
        this.store.remove("tenantStatus");
        //TODO: Do this the clean way
        window.localStorage.clear();

        window.location.reload();
      }
    } else {
      this.clicksCount = 1;
      this.showClicks = false;
      this.setClickTimeout(itemId);
    }
  }

  getTenantTheme() {
    this.width = "200";
    this.height = "110";
    this.imageLogo = this.store.get("dark_logo_url");
    this.imageBg = this.store.get("login_background_url");
  }

  showPassword() {
    this.show = !this.show;
  }

  handleTenant() {
    this.tenantService
      .getTenantDetails(this.tenantForm.value.tenant)
      .subscribe((data) => {
        if (data.length > 0) {
          let tenantDetails = data[0];
          this.env.tenant = tenantDetails.tenant;
          this.store.saveTenantWebDetails(tenantDetails);
          // this.imageLogo = tenantDetails.dark_logo_url;
          this.tenantStatus = true;
          this.changeServerApi(tenantDetails.server_ip);
          // load initial lang
          this.store.set("language", "en-EN");
          this.languageService
            .fetchTranslations(tenantDetails.app_name, "en-EN")
            .subscribe((data) => {
              this.languageService.set(data);
              window.location.reload();
            });
        } else {
          this.errorMsgTenant = "No App Found.";
        }
      });
  }
  handleLogin() {
    this.renderer.addClass(document.body, "loader-cursor");
    this.wrongPassword = false;
    this.token
      .login(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe({
        next: (data) => {
          if (data.access_token != null) {
            this.store.remove("username");
            this.store.set("username", this.loginForm.value.username);
            this.store.remove("access_token");
            this.store.set("access_token", data.access_token);
            this.service.load();
            if (
              this.store.get("theme_app_tone") &&
              this.store.get("theme_app_tone") === "dark-only"
            ) {
              document.body?.classList.remove("light-only");
              document.body?.classList.add("dark-only");
            }
            if (this.store.get("layout")) {
              this.layout.config.settings.layout = this.store.get("layout");
            }
            this.router.navigate([""]);
          } else {
            document.body.classList.remove("loader-cursor");
            this.errorMsg =
              "Login failed. Make sure your username and password are correct or contact your system administrator for a reset"; // login failed
          }
        },
        error: (error) => {
          document.body.classList.remove("loader-cursor");
          this.wrongPassword = true;
          console.log("Error", error);
        },
      });
  }

  private changeServerApi(server: string) {
    this.env.setServer(server);
    this.server = this.store.get("coreApi");
  }
}
