<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">{{ title | ioxTranslate }}</h6>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="cancel()"
  ></button>
</div>
<div class="modal-body">{{ subTitle | ioxTranslate }}</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" (click)="cancel()">
    Cancel
  </button>
  <button type="button" class="btn btn-danger btn-sm" (click)="confirm()">
    Confirm
  </button>
</div>
