import { Injectable } from "@angular/core";
import { SensorService } from "../api/sensor.service";
import { LastUpdateService } from "../api/last-update.service";
import { LocalStorageService } from "../core/storage.service";
import { Sensor } from "../../../models/api/sensor";
import { SensorValue } from "../../../models/api/sensor-value";
import { BehaviorSubject } from "rxjs";
import * as moment from "moment";
import { DeviceStateEnum } from "../../../models/api/enum-types/device-state-enum";
import { HttpErrorResponse } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class SensorManagerService {
  private sensorsSource = new BehaviorSubject<Sensor[]>([]);
  sensors$ = this.sensorsSource.asObservable();
  constructor(
    private sensorService: SensorService,
    private store: LocalStorageService,
    private lastUpdateService: LastUpdateService
  ) {}
  getSensors(callback) {
    this.sensorService.getSensors().subscribe((response) => {
      let sensors = response["sensors"];
      sensors = this.getSensorsNotNull(sensors);
      if (this.store.get("tenant") && this.store.get("tenant") !== "all") {
        sensors = sensors.filter((sensor) =>
          sensor.tenantId
            .toLowerCase()
            .startsWith(this.store.get("tenant").toLowerCase())
        );
      }
      this.getAllSensorsData(sensors, callback); // Pass callback here
    });
  }

  private getAllSensorsData(sensors: Sensor[], callback: () => void) {
    if (sensors && sensors.length > 0) {
      this.lastUpdateService.getLastUpdate().subscribe(
        (result) => {
          this.processLastUpdate(result, sensors);
          callback(); // Callback is called after processing last update
        },
        (err: HttpErrorResponse) => {
          console.log("Error:", err);
          callback(); // Also call callback in case of error to handle error states
        }
      );
    } else {
      this.sensorsSource.next(sensors);
      callback(); // Callback if there are no sensors to process
    }
  }

  private processLastUpdate(
    lastUpdateValues: SensorValue[],
    sensorList: Sensor[]
  ) {
    sensorList.forEach((sensor, idx) => {
      const lastUpdate = lastUpdateValues.filter((updVal) => {
        return updVal.sensorId === sensor.id;
      });
      if (lastUpdate.length === 1) {
        sensor["lastValue"] = lastUpdate[0]["lastSensorValue"];
        const lag = moment().diff(
          moment.utc(lastUpdate[0].savedAt).local(),
          "minutes"
        );
        sensor.lag = lag;
        sensor.lagUnit = this.getMeaning(lag, sensor.resolution);
      } else {
        sensor.savedAt = new Date(0);
        sensor.lag = 9999;
        sensor.lagUnit = this.getMeaning(99999, sensor.resolution);
      }
      const categories = sensor.categories;
      if ("Status" in categories) {
        if (categories.Status === "ACK") {
          sensor.lagUnit = "light";
        }
      }
    });
    this.sensorService.persist(sensorList);
  }
  private getSensorsNotNull(sensors: Sensor[]): Sensor[] {
    // Your existing logic
    for (let sensor of sensors) {
      if (sensor.tenantId == null || sensor.tenantId == undefined) {
        sensor.tenantId = "";
      }
      if (
        sensor.deviceState["state"] == null ||
        sensor.deviceState["state"] == undefined
      ) {
        sensor.deviceState["state"] = DeviceStateEnum.unregistered;
      }
    }
    return sensors;
  }

  private getMeaning(lagValue, samplingMinutes) {
    if (lagValue < 0) {
      // If lagValue is negative, it means the timestamp is in the future.
      return "danger";
    }
    if (samplingMinutes !== 0) {
      return lagValue > samplingMinutes * 10
        ? "danger"
        : lagValue > samplingMinutes * 3
        ? "warning"
        : "success";
    }

    return lagValue > 60 * 24
      ? "danger"
      : lagValue > 60
      ? "warning"
      : "success";
  }
}

//   getSensors() {
//     this.sensorService.getSensors().subscribe((response) => {
//       let sensors = response["sensors"];
//       sensors = this.getSensorsNotNull(sensors);
//       if (this.store.get("tenant") && this.store.get("tenant") != "all") {
//         sensors = sensors.filter((sensor) =>
//           sensor.tenantId
//             .toLowerCase()
//             .startsWith(this.store.get("tenant").toLowerCase())
//         );
//       }
//       this.getAllSensorsData(sensors);
//     });
//   }
//
//   private getSensorsNotNull(sensors: Sensor[]): Sensor[] {
//     // Your existing logic
//     for (let sensor of sensors) {
//       if (sensor.tenantId == null || sensor.tenantId == undefined) {
//         sensor.tenantId = "";
//       }
//       if (
//         sensor.deviceState["state"] == null ||
//         sensor.deviceState["state"] == undefined
//       ) {
//         sensor.deviceState["state"] = DeviceStateEnum.unregistered;
//       }
//     }
//     return sensors;
//   }
//
//   private getAllSensorsData(sensors: Sensor[]) {
//     if (sensors && sensors.length > 0) {
//       this.lastUpdateService.getLastUpdate().subscribe(
//         (result) => {
//           this.processLastUpdate(result, sensors);
//         },
//         (err: HttpErrorResponse) => console.log("Error:", err)
//       );
//     }
//     this.sensorsSource.next(sensors);
//   }
//
//   private processLastUpdate(
//     lastUpdateValues: SensorValue[],
//     sensorList: Sensor[]
//   ) {
//     sensorList.forEach((sensor, idx) => {
//       const lastUpdate = lastUpdateValues.filter((updVal) => {
//         return updVal.sensorId === sensor.id;
//       });
//
//       if (lastUpdate.length === 1) {
//         sensor["lastValue"] = lastUpdate[0]["lastSensorValue"];
//         const lag = moment().diff(
//           moment.utc(lastUpdate[0].savedAt).local(),
//           "minutes"
//         );
//         sensor.lag = lag;
//         sensor.lagUnit = this.getMeaning(lag, sensor.resolution);
//       } else {
//         sensor.savedAt = new Date(0);
//         sensor.lag = 9999;
//         sensor.lagUnit = this.getMeaning(99999, sensor.resolution);
//       }
//       const categories = sensor.categories;
//       if ("Status" in categories) {
//         if (categories.Status == "ACK") {
//           sensor.lagUnit = "light";
//         }
//       }
//     });
//     this.sensorService.persist(sensorList);
//     console.log("done");
//   }
//
//   private getMeaning(lagValue, samplingMinutes) {
//     if (lagValue < 0) {
//       // If lagValue is negative, it means the timestamp is in the future.
//       return "danger";
//     }
//     if (samplingMinutes !== 0) {
//       return lagValue > samplingMinutes * 10
//         ? "danger"
//         : lagValue > samplingMinutes * 3
//         ? "warning"
//         : "success";
//     }
//
//     return lagValue > 60 * 24
//       ? "danger"
//       : lagValue > 60
//       ? "warning"
//       : "success";
//   }
