import { ComponentFactoryResolver, Injectable } from "@angular/core";
import { lastValueFrom, Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EnvService } from "../core/env.service";

@Injectable({
  providedIn: "root",
})
export class InfluxService {
  private url: string;
  private urlIox: string;
  // private urlIox = "http://localhost:5000";
  constructor(private http: HttpClient, private env: EnvService) {
    this.url = this.env.pyApi;
    this.urlIox = this.env.ioxAdminApi;
  }

  public getLatestSensorValue(
    sensorId: string,
    measurement: string
  ): Observable<any> {
    const latestUrl =
      this.urlIox + "/api/v1/logs/" + measurement + "/" + sensorId;
    return this.http.get<any>(latestUrl);
  }

  getData(measurement, sensorId, from, to) {
    const url =
      this.urlIox + `/api/v1/logs/${measurement}/${sensorId}/${from}/${to}`;
    return this.http.get<any[]>(url);
  }

  getUsageData(measurement, field, sensorId, from, to) {
    const url =
      this.url +
      `/sv/ReportWorkHours/${measurement}` +
      `/${sensorId}/${field}/${from}/${to}`;
    return this.http.get<any[]>(url);
  }

  // getSensorLogs(sensorIds: string[], measurement: string) {
  //   // /api/v1/sensor_logs/GetDevRealKpi_1
  //   const url = `${this.urlIox}/api/v1/sensor_logs/${measurement}`;
  //   console.log(url);
  //   const body = { "sensorIds": sensorIds };
  //   return this.http.get<any[]>(url, body);

  // }

  getSensorLogs(sensorIds: string[], measurement: string) {
    const url = `${this.urlIox}/api/v1/sensor_logs/${measurement}`;
    const body = ({ sensorIds: `['${sensorIds.join("', '")}']` });


    console.log('Fetching sensor logs:', url);
    const params = new HttpParams().set('sensorIds', JSON.stringify(sensorIds));

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(url, body);
  }

  delete(
    time: string,
    user: string,
    measurement: string,
    sensorId,
    remark: string
  ) {
    return this.http.delete<any[]>(
      this.urlIox +
      "/api/v1/logs/delete/" +
      measurement +
      "/" +
      sensorId +
      "/" +
      time +
      "/" +
      user +
      "/" +
      remark
    );
  }

  patch(
    time: string,
    measurement: string,
    sensorId,
    user: string,
    remark: string
  ) {
    return this.http.patch<any[]>(
      this.urlIox +
      "/api/v1/logs/undo_delete/" +
      measurement +
      "/" +
      sensorId +
      "/" +
      time +
      "/" +
      user +
      "/" +
      remark,
      null
    );
  }
  editData(
    time: string,
    measurement: string,
    sensorId,
    data: Object,
    user: string,
    remark: string
  ): Observable<any> {
    return this.http.patch<any[]>(
      this.urlIox +
      "/api/v1/logs/edit_data/" +
      measurement +
      "/" +
      sensorId +
      "/" +
      time +
      "/" +
      user +
      "/" +
      remark,
      data
    );
  }

  getUserManual(): Observable<Blob> {
    const url = this.urlIox + "/api/v1/get-manual";
    return this.http.get(url, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/pdf"),
    });
  }

  // getUserManual(): Observable<Blob> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       Accept: "application/pdf",
  //     }),
  //     responseType: "blob" as "json",
  //   };
  //   return this.http.get<Blob>(this.urlIox + "/api/v1/get-manual", httpOptions);
  // }
}
