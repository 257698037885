import { Component, EventEmitter, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddEventPopupComponent } from "../add-event-popup/add-event-popup.component";
import { CalendarEventsService } from "../../services/strapi/calendar-events.service";
import { EventDeleteConfirmComponent } from "../event-delete-confirm/event-delete-confirm.component";

@Component({
  selector: "app-event-popup",
  templateUrl: "./event-popup.component.html",
  styleUrls: ["./event-popup.component.scss"],
})
export class EventPopupComponent implements OnInit {
  modalTitle: string;
  eventData: any;
  modalRef: any;

  onClose = new EventEmitter<boolean>();

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private calendarEventsService: CalendarEventsService
  ) {}

  ngOnInit(): void {
    console.log(this.eventData);
    this.modalTitle = this.eventData.attributes.title;
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  edit() {
    const modalRef = (this.modalRef = this.modalService.open(
      AddEventPopupComponent,
      { size: "lg" }
    ));
    modalRef.componentInstance.isAddMode = false;
    modalRef.componentInstance.eventData = this.eventData;
    modalRef.dismissed.subscribe((res) => {
      if (res) this.getUpdatedEvent(res);
    });
  }

  getUpdatedEvent(value) {
    this.calendarEventsService.updateEvent(value).subscribe((res) => {
      // this.eventData = res.data;
      // this.modalTitle = this.eventData.attributes.title;
      this.activeModal.dismiss(true);
    });
  }

  deleteEvent() {
    this.calendarEventsService
      .deleteEvent(this.eventData.id)
      .subscribe((res) => {
        this.activeModal.dismiss(true);
      });
  }

  delete() {
    const modalRef = (this.modalRef = this.modalService.open(
      EventDeleteConfirmComponent,
      { size: "lg" }
    ));
    modalRef.componentInstance.title = this.eventData.attributes.title;
    modalRef.dismissed.subscribe((res) => {
      if (res) this.deleteEvent();
    });
  }
}
