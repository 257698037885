import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal-confirm",
  templateUrl: "./modal-confirm.component.html",
  styleUrls: ["./modal-confirm.component.scss"],
})
export class ModalConfirmComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  constructor(public modal: NgbActiveModal) {}
  //
  //   save() {
  //     this.modal.close("Save click");
  //   }

  confirm() {
    this.modal.close("confirm");
  }

  cancel() {
    this.modal.dismiss("cancel");
  }
}
