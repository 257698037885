<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      <p class="mb-0">
        {{ "Copyright" | ioxTranslate }} {{ today | date : "y" }} ©
        {{ "Iotonix by AIT" | ioxTranslate }}
      </p>
    </div>
  </div>
</div>
