import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-media-edit-popup',
  templateUrl: './media-edit-popup.component.html',
  styleUrls: ['./media-edit-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaEditPopupComponent implements AfterViewInit {
  @Input() text: any;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @ViewChild('editor') editor: AngularEditorComponent;

  editorConfig: AngularEditorConfig = {
    sanitize: false
  };

  constructor(public modal: NgbActiveModal) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.editor.toggleEditorMode(true);
    }, 10);
  }

  save() {
    this.onSave.emit(this.text);
  }
}
