import { Component, PLATFORM_ID, Inject, OnInit } from "@angular/core";
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom } from "rxjs/operators";
import { LocalStorageService } from "./shared/services/core/storage.service";
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  // For Progressbar
  loaders;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private store: LocalStorageService
  ) {
    this.loaders = this.loader.value$.pipe(
      delay(1000),
      withLatestFrom(this.loader.value$),
      map((v) => v[1])
    );

    // if (isPlatformBrowser(this.platformId)) {
    //   translate.setDefaultLang('en');
    //   translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    // }
  }
  ngOnInit() {
    this.loadGoogleMapsScript();
  }

  loadGoogleMapsScript() {
    const googleMapsScriptId = "googleMaps";
    if (!document.getElementById(googleMapsScriptId)) {
      const script = document.createElement("script");
      script.id = googleMapsScriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.store.get(
        "GoogleMapsApiKey"
      )}&callback=initMap&libraries=places`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  }
}
