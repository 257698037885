import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      headTitle1: "General",
    },
    {
      title: "Home",
      icon: "home",
      type: "link",
      path: "/home",
      active: true,
    },
    {
      title: "Dashboards",
      icon: "widget",
      type: "sub",
      path: "/grafana",
      children: [],
    },
    {
      title: "Devices",
      icon: "learning",
      type: "link",
      path: "/devices",
    },
    {
      title: "Data Status",
      icon: "social",
      type: "sub",
      // path: "/dashboard",
      bookmark: true,
      children: [
        {
          path: "/dashboard",
          title: "Devices",
          type: "link",
        },
        {
          path: "/plant-dashboard",
          title: "Plants",
          type: "link",
        },
      ],
    },
    {
      title: "Master Data",
      icon: "social",
      type: "sub",
      // path: "/dashboard",
      bookmark: true,
      children: [
        {
          path: "/strapi-devices",
          title: "Devices",
          type: "link",
        },
        {
          path: "/strapi-plants",
          title: "Plants",
          type: "link",
        },
        {
          path: "/strapi-events",
          title: "Calendar",
          type: "link",
        },
        {
          path: "/strapi-customers",
          title: "Customers",
          type: "link",
        },
        {
          path: "/strapi-projects",
          title: "Projects",
          type: "link",
        },
      ],
    },

    {
      title: "Data Explorer",
      icon: "job-search",
      type: "sub",
      // active: true,
      children: [
        {
          path: "/data-explorer/sensor-data",
          title: "Sensors Data",
          type: "link",
        },
        {
          path: "/data-explorer/audit-logs",
          title: "Audit Logs",
          type: "link",
        },
        // { path: "/data-explorer/map", title: "Maps", type: "link" },
      ],
    },
    {
      title: "Alarms",
      icon: "faq",
      type: "link",
      path: "/alarms",
    },
    // {
    //   title: "Carbon Footprints",
    //   icon: "maps",
    //   type: "link",
    //   path: "/carbon-footprints",
    // },
    // {
    //   title: "Reports",
    //   icon: "maps",
    //   type: "link",
    //   path: "/reports",
    // },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
